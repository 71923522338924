<script setup lang="ts">
import Toaster from '@/components/ui/toast/Toaster.vue'

const { themeClass, radius } = useThemes()

useServerHead({
  htmlAttrs: {
    class: themeClass.value,
    style: `--radius: ${radius.value}rem;`
  }
})
</script>

<template>
  <div class="flex flex-col min-h-screen">
    <NuxtLoadingIndicator :color="false" class="z-100 bg-primary/80" />
    <LayoutHeader />
    <main class="flex-grow">
      <NuxtPage />
    </main>
    <Toaster />
    <LayoutFooter />
  </div>
</template>
